import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app works!';

  singlePaket = [
    {description: "Ex YU Kanali", value: "250+"},
    {description: "HD Kanali", value: "20+"},
    {description: "Kanali za odrasle", value: "10+"},
    {description: "Radio Stanice", value: "300+"},
    {description: "VoD - ex-YU filmovi", value: "250+"},
    {description: "Do 7 dana premotavanje unazad", value: "yes"},
    {description: "Maksimalan broj uredjaja", value: "6"},
    {description: "Istovremeno gledanje na ", value: "1"}
  ];

  doublePaket = [
    {description: "Ex YU Kanali", value: "250+"},
    {description: "HD Kanali", value: "20+"},
    {description: "Kanali za odrasle", value: "10+"},
    {description: "Radio Stanice", value: "300+"},
    {description: "VoD - ex-YU filmovi", value: "250+"},
    {description: "Do 7 dana premotavanje unazad", value: "yes"},
    {description: "Maksimalan broj uredjaja", value: "6"},
    {description: "Istovremeno gledanje na ", value: "2"}
  ];

  maxPaket = [
    {description: "Ex YU Kanali", value: "250+"},
    {description: "HD Kanali", value: "20+"},
    {description: "Kanali za odrasle", value: "10+"},
    {description: "Radio Stanice", value: "300+"},
    {description: "VoD - ex-YU filmovi", value: "250+"},
    {description: "Do 7 dana premotavanje unazad", value: "yes"},
    {description: "Maksimalan broj uredjaja", value: "6"},
    {description: "Istovremeno gledanje na ", value: "4"}
  ];

  boxes = [
    {header: "Single Paket", table: this.singlePaket}, 
    {header: "Double Paket", table: this.doublePaket}, 
    {header: "Max Paket", table: this.maxPaket}
  ];

  
  outerGridListHeight = 500;
  innerGridListTile = (this.outerGridListHeight / (this.singlePaket.length + 4)) - 1;

  maxBoxCols = 10;
  boxWidth = 20;

}
