import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-adverts',
  templateUrl: './adverts.component.html',
  styleUrls: ['./adverts.component.css']
})
export class AdvertsComponent implements OnInit {

  constructor() {
  }

   ngOnInit() {
   }
}
