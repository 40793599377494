import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-probaj',
  templateUrl: './probaj.component.html',
  styleUrls: ['./probaj.component.scss']
})
export class ProbajComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
