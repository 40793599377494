<mat-toolbar color="primary">

  <!-- grid-list damit logo in der mitte global zentriert ist -->
<mat-grid-list class="container-fluid" cols="3" rowHeight="50px">
  <mat-grid-tile class="d-flex">
    <!-- LINKS -->
    <div class="d-flex w-100 h-100">
      <div class="bg-light kamarad-logo-bg">
        <img src="assets/img/Kamarad_Logo.png">
      </div>
    </div>



  </mat-grid-tile>

  <!-- MITTE -->
  <mat-grid-tile><img src="assets/img/go4yu_logo.png" style="height: 50px;"></mat-grid-tile>

  <!-- RECHTS -->
  <mat-grid-tile class="d-flex justify-content-end">
    <div class="d-flex justify-content-end w-100">
      <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
        <mat-icon>favorite</mat-icon>
      </button>
      <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
        <mat-icon>share</mat-icon>
      </button>
    </div>
  </mat-grid-tile>
</mat-grid-list>

<!-- <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon>menu</mat-icon>
    </button> -->

<!-- <span class="example-spacer"></span> -->
<!-- <a routerLink="probaj">Probaj odmah!</a> -->


</mat-toolbar>

<div class="container-fluid">

    <!-- TABLES -->
    <div class="d-flex justify-content-around m-5" >
    <mat-grid-list class="internalMatGrid float-left" cols="1" [rowHeight]="innerGridListTile" [style.width.%]="boxWidth" *ngFor="let box of boxes">
        <!-- ############## -->
        <mat-grid-tile [rowspan]="2" color="primary"><div class="h-100 w-100 d-flex align-items-center justify-content-center mx-2"><h2>{{ box.header }}</h2></div></mat-grid-tile>
        <!-- ############## -->
        <mat-grid-tile *ngFor="let item of box.table">
          <div class="d-flex justify-content-between align-items-center h-100 w-100 mx-2">
            <span>{{item.description}}</span>
            <!-- Choice -->
            <span *ngIf="item.value != 'yes'">{{item.value}}</span>
            <mat-icon *ngIf="item.value == 'yes'">done</mat-icon>
          </div>
        </mat-grid-tile>
        <!-- ############## -->
        <mat-grid-tile [rowspan]="2">
          <button mat-raised-button color="accent">Probaj!</button>
        </mat-grid-tile>
        <!-- ############## -->
      </mat-grid-list>
  </div>

  <div class="d-flex justify-content-center flex-column">
    <h2>Probaj besplatno!</h2>
    <app-probaj></app-probaj>
  </div>

</div>